<template>
  <div class="owner-property-code-input">
    <div v-if="value" class="col-12 sm-col-6 property-code valid p-1">
      <div>
        <span class="badge mr-1">
          <i class="fas fa-check"></i>
        </span>
        <p class="d-inline-block">
          <span class="font-bold d-block">{{ value }}</span>
        </p>
        <div v-if="showStatus" class="d-inline-block ml-1">
          <span v-if="confirmed" class="owner-approval approved">Approved</span>
          <span v-else class="owner-approval pending">Pending</span>
        </div>
      </div>
    </div>
    <div v-else>
      <p class="p-1">Owner code is not applied</p>
    </div>

    <fd-button class="bordered main mt-2" @click="openOwnerCodeModal">
      Add Owner Code
    </fd-button>

    <!-- ============================== Modal ============================== -->
    <modal v-model="ownerCodeModal" :persistent="isLoading">
      <fd-form class="card owner-code-form p-3" @submit="applyOwnerCode">
        <h4 class="mb-4">Add Owner Code</h4>

        <div v-if="!isLoading" class="mb-2">
          <div
            v-if="value !== '' && ownerPropertyCodeInfo"
            class="
              col-12
              sm-col-6
              d-flex
              align-items-start
              property-code
              bordered-card
              valid
              p-1
            "
          >
            <span class="badge mr-1">
              <i class="fas fa-check"></i>
            </span>
            <p class="d-inline-block">
              <span>{{ ownerPropertyCodeInfo.appUser.name }}</span>
              <span class="font-bold d-block">{{
                ownerPropertyCodeInfo.code
              }}</span>
            </p>
            <div v-if="showStatus" class="d-inline-block ml-1">
              <span v-if="confirmed" class="owner-approval approved"
                >Approved</span
              >
              <span v-else class="owner-approval pending">Pending</span>
            </div>
          </div>
          <div v-if="errorMessage !== ''" class="px-2 fg-danger">
            <span class="badge mr-1"><i class="fas fa-times"></i></span>
            <p class="fg-danger d-inline-block">{{ errorMessage }}</p>
          </div>
        </div>

        <div class="d-flex mb-2">
          <fd-input
            v-model="ownerCode"
            class="col-12 px-1"
            label="Owner Property Code"
            name="ownerPropertyCode"
            type="text"
            placeholder="Enter the code from owner"
            :validators="[validator.required]"
          >
            <template #post-input-box>
              <fd-button
                type="submit"
                loadingEnabled
                :isLoading="isLoading"
                :disabled="!canApply"
                class="main h-100 ml-1"
                >Apply</fd-button
              >
            </template>
          </fd-input>
        </div>

        <div class="d-flex justify-content-end">
          <fd-button medium @click="ownerCodeModal = false">Close</fd-button>
        </div>
      </fd-form>
    </modal>
  </div>
</template>

<script>
import { required } from "@/components/GlobalComponents/FormComponents/Validator/rules";
import APIException from "@/utils/error/APIException";

export default {
  components: {},
  mixins: [],
  props: {
    value: {
      type: String,
      default: ""
    },
    propertyId: {
      type: [Number, String]
    },
    confirmed: {},
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      ownerCode: "",
      ownerPropertyCodeInfo: null,
      currentCode: "",

      ownerCodeModal: false,
      isLoading: false,
      errorMessage: "",

      validator: {
        required: required
      }
    };
  },
  computed: {
    isNewCode() {
      return this.currentCode !== this.value;
    },
    canApply() {
      // Detect if same code is pasted as previous and prevent reapply
      return this.currentCode !== this.ownerCode;
    },
    showStatus() {
      return (
        this.confirmed !== undefined && this.confirmed !== "" && !this.isNewCode
      );
    }
  },
  watch: {
    isLoading(val) {
      this.$emit("loading", val);
    }
  },
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.init();
  },
  methods: {
    async init() {
      this.isLoading = true;
      this.ownerCode = this.value;
      if (this.isEdit) {
        this.currentCode = this.value;
      }
      if (this.value) {
        await this.getOwnerPropertyCode();
      }
      this.isLoading = false;
    },
    openOwnerCodeModal() {
      if (this.value) {
        this.ownerCode = this.value;
      }
      this.ownerCodeModal = true;
    },
    async getOwnerPropertyCode() {
      this.isLoading = true;
      let vm = this;

      try {
        let data = await vm.$store.dispatch(
          "manageProperty/getOwnerPropertyCode",
          {
            propertyId: vm.propertyId,
            code: vm.ownerCode
          }
        );
        this.ownerPropertyCodeInfo = this._.cloneDeep(data);
        this.isLoading = false;
        return data;
      } catch (error) {
        this.isLoading = false;
        this.handleCodeCheckError(error);
      }

      this.isLoading = false;
    },

    setOwnerCode(data) {
      this.$emit("input", data.code);
    },
    clearOwnerCode() {
      this.ownerPropertyCodeInfo = null;
      this.$emit("input", "");
    },

    handleCodeCheckError(error) {
      const ERROR = {
        NOT_AVAILABLE: 6, // Owner Code Not Available
        NOT_FOUND: 7 // Not Found
      };

      let exception = new APIException(error);
      if (exception.isCustomExceptionError()) {
        switch (exception.getExceptionCode()) {
          case ERROR.NOT_AVAILABLE:
            this.clearOwnerCode();
            this.errorMessage = "The code is not available.";
            break;
          case ERROR.NOT_FOUND:
            this.clearOwnerCode();
            this.errorMessage = "The code entered is not found.";
            break;

          default:
            break;
        }
      } else {
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "An unexpected error occured. Please try again later."
        });
      }
    },

    async applyOwnerCode() {
      // Clear error message
      this.errorMessage = "";

      // Check owner code validity
      let data = await this.getOwnerPropertyCode();
      this.setOwnerCode(data);
    }
  }
};
</script>

<style lang="scss">
.owner-property-code-input {
  border: solid 1px #cacaca;
  border-radius: 5px;
  padding: 15px;
  .owner-code-form {
    @media #{$breakpoint-up-md} {
      min-width: 600px;
    }
  }
  .property-code {
    @mixin badge($color) {
      .badge {
        border-radius: 50%;
        width: 25px;
        height: 25px;
        background: $color;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        i {
          color: white;
        }
      }
    }
    &.valid {
      @include badge($color-success);
    }
    &.invalid {
      @include badge($color-danger);
    }
  }
  .owner-approval {
    border-radius: 4px;
    padding: 2px 12px;
    font-size: 0.9em;
    &.approved {
      border: solid 1px $color-success;
      color: $color-success;
    }
    &.pending {
      border: solid 1px $color-warn;
      color: $color-warn;
    }
  }
}
</style>
