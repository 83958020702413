class APIException {
    error;
    context;

    constructor(error) {
        this.error = error;
    }

    getExceptionCode() {
        if (this.isCustomExceptionError) {
            return this.error.response.data.exceptionCode;
        } else {
            throw "APIException: This is not a custom exception error.";
        }
    }

    isCustomExceptionError() {
        let code = this.error.response.data.exceptionCode;
        return (
            code == null || code == undefined || Object.keys(code).length === 0
        );
    }
}

export default APIException;
